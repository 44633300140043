import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import BrandOpportunities from './BrandOpportunities'
import Client from '../Client'
import BrandStore from '../../stores/client'
import ChannelsStore from '../../stores/channels'
import config from '../../config'

const detailsConfig = [
  { key: 'n', name: 'ID', editable: false, creatable: false, type: 'string' },
  {
    key: 'name',
    name: 'Name',
    editable: true,
    creatable: true,
    type: 'string',
    dataCy: 'brandName',
  },
  {
    key: 'onboardingStep',
    name: 'Onboarding Status',
    editable: true,
    creatable: true,
    type: 'select',
    options: [
      // 'company', 'plans', 'discovery', 'keywords', 'competitors', 'verify', 'running', 'ready'
      { value: 'company', label: 'Brand' },
      { value: 'plans', label: 'Plans' },
      { value: 'discovery', label: 'Search' },
      { value: 'keywords', label: 'Keywords' },
      { value: 'competitors', label: 'Competitors' },
      { value: 'verify', label: 'Verify Email' },
      { value: 'running', label: 'Search running' },
      { value: 'ready', label: 'Search ready' },
      { value: 'done', label: 'Done' },
    ],
  },
  {
    key: 'credits',
    name: 'Credits',
    editable: false,
    creatable: false,
    type: 'string',
    dataCy: 'brandName',
  },
  {
    key: 'assisted',
    name: 'Assisted Plan',
    editable: true,
    creatable: false,
    type: 'bool',
  },
  {
    key: 'paywall',
    name: 'Enable Paywall',
    editable: true,
    creatable: false,
    type: 'bool',
  },
  {
    key: 'intelligenceEnabled',
    name: 'Enable Intelligence',
    editable: true,
    creatable: false,
    type: 'bool',
  },
  {
    key: 'enableLargeExports',
    name: 'Enable Large Exports',
    editable: true,
    creatable: false,
    type: 'bool',
  },
  {
    key: 'description',
    name: 'Description',
    editable: true,
    creatable: true,
    type: 'string',
    dataCy: 'brandDescription',
  },
  // { key: 'categories', name: 'Classification', dataCy:'brandCategories', editable: true, creatable: true, type: 'multiselect', kind: 'chips', options: config.CLASSIFICATIONS },
  {
    key: 'status',
    name: 'Status',
    editable: true,
    creatable: true,
    type: 'select',
    kind: 'status',
    dataCy: 'brandStatus',
    options: [
      { value: 'active', label: 'Active' },
      { value: 'verified', label: 'Verified' },
      { value: 'deleted', label: 'Deleted' },
    ],
  },
  { key: 'totalSpent', name: 'Total Spent', type: 'string' },
  { key: 'logoUrl', editable: true, creatable: true, type: 'image' },
]

const AddCreditsDialog = ({ open, onClose, onAdd }) => {
  const [value, setValue] = useState('0')

  const onAddCredits = async () => {
    const { success } = await onAdd(parseInt(value))
    if (success) {
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Add credits</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Credits'
          fullWidth
          onChange={({ target: { value } }) => setValue(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={onAddCredits} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

@withRouter
@observer
class Brand extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: {
        params: { id },
      },
    } = props
    this.onLoadStores(id)
    this.state = { credits: false }
  }

  onLoadStores = (id) => {
    this.store = new BrandStore(id, 'brand', false)
    // this.channelsStore = {}
    // if (id !== 'create') {
    //   this.channelsStore = new ChannelsStore('brand', id)
    // }
  }

  onOpenCredits = () => this.setState({ credits: true })
  onCloseCredits = () => this.setState({ credits: false })

  get detailsFields() {
    const assistedLock = {
      key: 'assistedLock',
      name: 'Assisted Lock',
      editable: true,
      creatable: false,
      type: 'bool',
    }
    const { details } = this.store

    return details.assisted
      ? [...detailsConfig.slice(0, 4), assistedLock, ...detailsConfig.slice(4)]
      : detailsConfig
  }

  render() {
    const { credits } = this.state
    const {
      match: {
        params: { id, channel },
      },
    } = this.props
    const { details, loading } = this.store

    // const channelsProps = {
    //   channels: this.channelsStore.channels,
    //   thinking: this.channelsStore.channelsThinking
    // }
    //
    // const similarProps = {
    //   channels: this.channelsStore.similar,
    //   thinking: this.channelsStore.similarThinking
    // }

    return (
      <>
        <Client
          loading={loading}
          discovery={false}
          id={id}
          channel={channel}
          client={'brand'}
          clientStore={this.store}
          details={details}
          detailsConfig={this.detailsFields}
          // channelsStore={this.channelsStore}
          // channelsProps={channelsProps}
          // similarProps={similarProps}

          onAddCredits={this.onOpenCredits}
          onUpdateStores={this.onLoadStores}
        >
          {/*<Grid item xs={12}>*/}
          {/*  <BrandOpportunities clientId={id} clientName={details.name}/>*/}
          {/*</Grid>*/}
        </Client>
        <AddCreditsDialog
          open={credits}
          onClose={this.onCloseCredits}
          onAdd={(value) => this.store.addCredits(value)}
        />
      </>
    )
  }
}

export default Brand
